@import "./variables";

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-bottom-20 {
  margin-bottom: 20px;
  margin-left: 20px;
}

.margin-left-bottom-10 {
  margin-bottom: 10px;
  margin-left: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-5 {
  margin-left: 5px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.font-bold {
  font-weight: $font-weight-700;
}

.font-16 {
  font-size: $font-16;
}

.flex-space-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.flex-start {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-with-center-align {
  align-items: center;
  display: flex;
}

.text-disabled {
  color: #99b0b2;
}

.vertical-align-middle {
  vertical-align: middle;
}

.error-text {
  color: $danger;
  font-size: $font-10;
}
