@import "../../../../styles/utils";

.address-change {
  &__container {
    align-items: center;
    display: flex;
    margin-top: 10px;
  }

  &__loader {
    margin-left: 8px;
    padding: 0;
  }

  &__title {
    font-weight: $font-weight-700;
  }

  &__description {
    margin-bottom: 20px;
  }

  &__reason {
    margin: 0 auto;
    text-align: left;
    width: 328px;
  }

  &__modal {
    .modal__container {
      max-width: 540px;
    }

    .modal__content {
      overflow: visible;
    }
  }
}
