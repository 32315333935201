@import "../../styles/utils";

.set-password {
  &__form {
    &-error {
      .rcl-alert {
        padding: 10px;

        &-container {
          justify-content: center;
        }
      }
    }
  }
}
