@import "../../../styles/utils";

$tab-nav-items-padding: 5px;
$tab-pane-padding: 15px;
$scrollbar-height: 15px;
$underline-height: 2px;
$tab-nav-text-height: 35px;
$tab-nav-height: $tab-nav-text-height;
$disabled-with-opacity: rgba(
  $color: $disabled,
  $alpha: 0.5,
);

$tab-default-text-color: #205057;
$tab-default-bg-color: #edf1f2;
$tab-selected-text-color: #fff;
$tab-selected-bg-color: #205057;
$tab-hover-text-color: #205057;
$tab-hover-bg-color: #c9d5d7;
$tab-disabled-text-color: #99b0b2;
$tab-disabled-bg-color: #edf1f2;

@mixin generate-gradiant-for-nav-button-container($rotation) {
  background: linear-gradient(
    $rotation,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.rtabs {
  width: 100%;

  &,
  * {
    box-sizing: border-box;
  }

  &__nav {
    height: $tab-nav-height;
    margin-top: 20px;
    max-width: 100%;
    overflow-y: hidden;
    position: relative;

    &-container {
      display: flex;
      height: calc(#{$tab-nav-height} + #{$scrollbar-height});
      left: 50%;
      overflow-x: scroll;
      position: absolute;
      scroll-behavior: smooth;
      top: 0;
      transform: translateX(-50%);
      width: 100%;
    }

    &-item {
      align-items: center;
      background-color: $tab-default-bg-color;
      border-radius: 8px;
      color: $tab-default-text-color;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      justify-content: center;
      line-height: 1.5;
      margin: 0 $tab-nav-items-padding;
      padding: 8px 4px;
      text-align: center;
      white-space: nowrap;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &--text {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: 8px 12px;

        &__active {
          font-weight: $font-weight-700;
        }
      }

      &--active {
        background-color: $tab-selected-bg-color;
        color: $tab-selected-text-color;
      }

      &--disabled {
        background-color: $tab-disabled-bg-color;
        color: $tab-disabled-text-color;
        cursor: not-allowed;
      }

      &--hidden {
        display: none;
      }

      &--separator {
        background-color: $blueish-black-16;
        display: none;
        height: 20px;
        margin: 0 10px;
        width: 2px;
      }

      &:hover {
        background-color: $tab-hover-bg-color;
        color: $tab-hover-text-color;
      }
    }

    &-underline {
      background-color: $blueish-black-16;
      bottom: 0;
      display: block;
      height: $underline-height;
      left: 0;
      overflow: hidden;
      position: absolute;

      &-indicator {
        background-color: $blueish-black;
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        top: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;

        &--hidden {
          opacity: 0;
        }
      }

      &--hide {
        display: none;
      }
    }

    &-button {
      align-items: center;
      background-color: $light-lemon;
      border: 0;
      border-radius: 50%;
      color: $blueish-black;
      cursor: pointer;
      display: flex;
      height: $tab-nav-text-height;
      justify-content: center;
      outline: none;
      padding: 0;
      width: $tab-nav-text-height;

      &-container {
        align-items: center;
        background-color: $white;
        display: flex;
        height: calc(100% - #{$underline-height});
        justify-content: center;
        position: absolute;
        top: 0;
        transition: opacity 0.2s ease;

        &::after {
          background: transparent;
          content: "";
          height: 100%;
          pointer-events: none;
          position: absolute;
          top: 0;
          width: $tab-nav-text-height;
        }
      }

      &--left {
        left: 0;

        &::after {
          @include generate-gradiant-for-nav-button-container(90deg);
          left: 100%;
        }
      }

      &--right {
        right: 0;

        &::after {
          @include generate-gradiant-for-nav-button-container(270deg);
          right: 100%;
        }
      }

      &--hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &__pane {
    opacity: 0;
    padding: $tab-pane-padding 0;
    transition: opacity 0.2s ease;

    &--opacity-0 {
      opacity: 0;
    }

    &--opacity-100 {
      opacity: 1;
    }
  }

  &--touchable {
    .rtabs__nav {
      &-button {
        @include viewport-is(tab-mobile) {
          display: none;
        }
      }

      &-container {
        height: $tab-nav-height;
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;

    &--vertical {
      flex-direction: row;
    }
  }

  &__tabs {
    border-bottom: 1px solid rgba($tertiary-accent, 0.16);
    display: flex;
    flex-wrap: wrap;

    &--vertical {
      flex-direction: column;
    }
  }

  &__tab {
    color: rgba($primary-text, 0.65);
    cursor: pointer;
    padding: 12px 15px;
    white-space: nowrap;

    &:first-of-type {
      padding-left: 4px;
    }

    &:focus {
      background-color: transparent;
      outline: 0;
    }

    &--selected {
      background: transparent;
      color: $primary-text;

      &:focus {
        background-color: transparent;
        color: $primary-text;
      }

      &.rtabs__tab--vertical {
        border-left: 2px solid $cool-gray-20;
      }

      .rtabs__removable {
        position: relative;
      }

      .rtabs__removable-text {
        margin-right: 10px;
      }

      .rtabs__removable-icon {
        font-size: 18px;
        position: absolute;
        right: 0.5em;
        top: 0.2em;
      }
    }

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;

      .rcl-tooltip {
        max-width: fit-content;
      }
    }

    &--first {
      border-left-width: 1px;
    }

    &--vertical {
      &:hover {
        background: $cool-gray-20-12;
      }
    }
  }

  &__accordion {
    flex-direction: column;

    .rtabs__tab {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .rtabs__tab:hover {
      background: $cool-gray-20-12;
    }

    .keyboard-arrow-up {
      transform: rotateX(180deg);
    }

    .rtabs__divider {
      border-bottom: 1px solid #f3f3f3;
      margin-left: 30px;
    }

    .rtabs__panel {
      margin-top: 0;
      transition: 0.5s;
    }
  }

  &__panel {
    margin-top: -1px;
    padding: 1em 0;
  }

  &__inkbar {
    background-color: $secondary-accent;
    bottom: 0;
    height: 2px;
    margin-top: -2px;
    position: relative;
    transition: left 800ms cubic-bezier(0.23, 1, 0.32, 1);

    &-wrapper {
      width: 100%;
    }
  }

  &__showmore {
    cursor: pointer;
    margin-left: 10px;
    outline: none;
    position: relative;
    white-space: nowrap;
    z-index: 1;

    &:hover {
      .rtabs__showmore-text {
        display: block;
      }

      .rtabs__showmore-text--selected {
        display: none;
      }
    }

    &-text {
      background: $white;
      border-radius: 3px;
      box-shadow: 0 3px 6px rgba($tertiary-accent, 0.16);
      display: none;
      padding: 4px 8px;
      position: absolute;
      transform: translate(-40%, 30%);
    }

    &-list {
      background: $white;
      border-radius: 4px;
      box-shadow: 0 3px 6px rgba($tertiary-accent, 0.16);
      display: none;
      position: absolute;
      right: -1px;
      top: 100%;
      width: 180px;

      .rtabs__tab {
        border-radius: 3px;
        box-sizing: border-box;
        margin: 4px;
        padding-left: 8px;

        &:hover {
          background: rgba($tertiary-accent, 0.16);
        }

        &--selected {
          background: $secondary-accent;
          color: $white;

          &:hover {
            background: $secondary-accent;
            color: $white;
          }
        }
      }

      &--opened {
        display: block;
      }
    }

    &-label {
      border-radius: 3px;
      bottom: -4px;
      margin-left: -10px;
      margin-top: 4px;
      padding: 2px 5px 5px;
      position: relative;

      &:hover {
        background: rgba($tertiary-accent, 0.16);
      }

      svg {
        fill: $secondary-accent;
        height: 20px;
        vertical-align: middle;
        width: 20px;
      }

      &--selected {
        background: $secondary-accent;

        &:hover {
          background: $secondary-accent;
        }

        svg {
          fill: $white;
        }
      }
    }
  }
}
