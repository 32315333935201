@import "../../../../styles/utils.scss";

.organisation-checklist {
  width: 812px;

  &__confirm-button {
    margin-top: 20px;
  }

  &__accounts {
    margin-top: 10px;

    &-bold {
      font-weight: $font-weight-700;
    }

    &-normal {
      font-weight: $font-weight-400;
    }
  }

  &__verify {
    font-weight: $font-weight-400;
    margin-top: 10px;
  }

  &__certifier {
    &-comment {
      padding: 0 20px;

      &--field {
        margin-bottom: 20px;
        margin-top: 2px;

        .text-field-holder {
          height: 47px;
        }
      }
    }

    &-audio {
      margin-top: 12px;
      padding: 0;

      .text-field-holder {
        height: 47px;
      }
    }
  }

  &__auditor-acknowledgement {
    margin-top: 20px;
  }

  &__accounting-error {
    align-items: center;
    color: $dark-red;
    display: flex;
    font-weight: $font-weight-400;
    justify-content: flex-start;
  }

  &__errors {
    align-items: center;
    color: $dark-red;
    display: flex;
    font-weight: $font-weight-400;
    justify-content: flex-end;

    &-text {
      margin-left: 5px;
    }
  }

  &__unordered-list {
    margin: 0;
    padding: 4px 20px;

    &--disabled {
      margin-bottom: 10px;
      .yes-no-input__label--text {
        opacity: 0.4;
      }
    }

    &--hide {
      list-style: none;
    }
  }

  &__number {
    &-bold {
      font-weight: $font-weight-700;
    }
  }

  &__error {
    color: $danger;
    font-size: $font-12;
    font-weight: $font-weight-700;
  }

  &__category {
    font-weight: $font-weight-400;
    margin-top: 20px;

    &-description {
      font-size: $font-12;
    }
  }

  &__acknowledge {
    display: flex;
    font-weight: $font-weight-400;
    justify-content: space-between;

    .rcl-checkbox {
      &__label {
        font-weight: $font-weight-400;
        margin-right: 32px;
      }
    }
  }

  &__upload {
    &-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      &--header {
        font-weight: $font-weight-700;
      }
    }

    &-list {
      align-items: center;
      box-shadow: none;
      display: flex;
      font-weight: $font-weight-400;
      justify-content: space-between;

      &--wrapper {
        div:nth-child(even) {
          padding-left: 10px;
        }
      }

      &--card {
        border: 1px solid $blueish-black-16;
        box-shadow: none;
        margin-top: 10px;
        padding: 10px 20px;
      }

      &--name {
        align-items: center;
        display: flex;
        justify-content: space-between;

        &__truncate {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 150px;
        }

        .icon {
          margin-right: 10px;
        }
      }
    }

    &-btn {
      align-items: center;
      display: flex;

      &--count {
        margin-right: 10px;
      }
    }
  }

  &__delivery-notes-wrapper {
    column-gap: 10px;
    display: flex;
    flex-direction: row;
  }

  &__audio {
    &-all {
      border: 1px solid $blueish-black-16;
      border-radius: 10px;
      box-shadow: none;
    }

    &-btn {
      margin-bottom: 10px;
    }

    &-card {
      align-items: center;
      display: flex;
      font-weight: $font-weight-400;
      justify-content: space-between;

      &--content {
        border: 1px solid $blueish-black-16;
        box-shadow: none;
        padding: 6px 16px;
      }

      &--icon {
        align-items: center;
        cursor: pointer;
        display: flex;

        .choose-file {
          &__contract-delete {
            &--text {
              min-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 200px;
            }
          }
        }
      }

      &--wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;

        .card {
          width: 60%;
        }
      }

      &--vertical-line {
        background-color: $blueish-black-16;
        height: 44px;
        margin: 0 20px;
        width: 2px;
      }

      &--satisfy {
        font-weight: $font-weight-400;
        margin-bottom: 10px;
      }
    }

    &-text {
      font-weight: $font-weight-400;
      margin: 10px 0;

      &--one {
        margin-top: 0;
      }
    }
  }

  &__btn {
    margin-top: 40px;

    &-save {
      margin-left: 10px;
    }
  }

  &__information {
    font-weight: $font-weight-400;
    margin: 20px 0;

    &-description {
      font-weight: $font-weight-400;
      margin-bottom: 10px;
    }
  }

  &__radio {
    cursor: pointer;
    &-wrapper {
      display: flex;

      &--audio {
        .yes-no-input {
          &__label {
            display: none;
          }
        }
      }
    }

    input[type="radio"]::after {
      background-color: $white;
      border: 1.5px solid $blueish-black;
      border-radius: 3px;
      content: "";
      cursor: pointer;
      display: inline-block;
      height: 14px;
      width: 14px;
    }

    input[type="radio"]:checked::after {
      background-color: $blueish-black;
      border: none;
      border-radius: 3px;
      content: "";
      cursor: pointer;
      display: inline-block;
      height: 16px;
      width: 16px;
    }

    &-icon {
      position: relative;
      right: 12px;

      svg {
        fill: $white;
        height: 8px !important;
        width: 8px !important;
      }
    }

    &-positive {
      margin-right: 16px;
    }
  }

  &__alphabet {
    font-size: $font-24;
    font-weight: $font-weight-700;
    margin-bottom: 20px;
  }

  &__date-time {
    align-items: center;
    display: flex;
    margin-bottom: 30px;
  }

  &__control-session {
    hr {
      margin: 40px 0;
    }

    .rcl-popover__parent {
      width: 100%;
    }

    &-header {
      font-size: $font-16;
      font-weight: $font-weight-700;
      margin-bottom: 10px;
    }
  }

  &__time {
    margin-left: 20px;

    &-header {
      font-size: $font-16;
      font-weight: $font-weight-700;
    }
  }

  &__date {
    &-header {
      font-size: $font-16;
      font-weight: $font-weight-700;
    }

    &-start-time {
      font-size: $font-16;
      font-weight: $font-weight-400;
    }
  }

  &__logo {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-right: 16px;

    &-icon {
      bottom: 16px;
      position: relative;
    }
  }

  &__status {
    &-wrapper {
      font-weight: $font-weight-400;
      margin-right: 20px;
    }

    &-description {
      font-size: $font-12;
      margin-top: 5px;
    }

    &-header {
      font-weight: $font-weight-400;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: space-between;

    &-content {
      display: flex;
      font-weight: $font-weight-400;
      font-weight: $font-weight-400;
      justify-content: space-between;
    }

    &-check {
      display: flex;

      &--positive {
        margin-right: 20px;
      }
    }

    &-wrapper {
      font-weight: $font-weight-400;
      margin-bottom: 40px;
    }

    &-horizontal-line {
      border-color: rgba($color: $blueish-black, $alpha: 0.08);

      &--upload {
        margin-top: 20px;
      }
    }

    &-title {
      margin-bottom: 10px;

      &--reserved {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__remarks-wrapper {
    hr {
      margin: 40px 0;
    }
  }

  &__deviation {
    font-weight: $font-weight-400;
    margin-bottom: 20px;
    margin-top: 40px;

    &-card {
      border: 1px solid $blueish-black-16;
      border-radius: 10px;
      box-shadow: none;

      &--legend {
        border: 1px solid $blueish-black-16;
        border-radius: 10px;
        box-shadow: none;
      }

      &-vat-percentage {
        column-gap: 20px;
        display: flex;

        &-comment {
          margin: 20px 0;
        }

        > div {
          flex: 1;
        }
      }
    }

    &__wrapper {
      margin: 20px 0;
    }

    &-scale {
      display: flex;
      font-weight: $font-weight-400;
      justify-content: space-between;

      &--header {
        font-size: $font-12;
      }

      &--declare {
        direction: rtl;
      }
    }

    &-legend {
      display: flex;

      &--individual {
        margin-right: 30px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &--wrapper {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
      }

      &--status,
      &--deadline,
      &--certificate {
        &__good {
          color: $pastal-green;
          font-weight: $font-weight-700;
        }

        &__average {
          color: $warning;
          font-weight: $font-weight-700;
        }

        &__bad {
          color: $normal-red;
          font-weight: $font-weight-700;
        }

        &-disabled {
          color: $blueish-black-40;
          font-weight: $font-weight-400;

          &-bold {
            font-weight: bold;
          }
        }
      }

      &-monthly-variation {
        span:not(:last-child)::after {
          content: " | ";
        }

        span {
          font-size: 12px;
        }
      }
    }
  }

  &__account {
    font-weight: $font-weight-400;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  &__condition {
    font-weight: $font-weight-400;
    margin-bottom: 10px;
    margin-top: 40px;

    &-list {
      margin-bottom: 0;
      margin-right: 40px;
      margin-top: 0;
      padding-left: 20px;
    }

    &-text {
      font-weight: $font-weight-400;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .status {
        &__step-icon {
          border-radius: 100%;
          border-width: 5px;
          height: 24px;
          position: relative;
          width: 24px;
        }
      }

      .icon {
        height: 24px;
        width: 24px;

        &__content {
          height: 0.875rem;
          position: unset;
          width: 0.875rem;
        }
      }
    }

    &-items {
      display: flex;
      font-weight: $font-weight-400;
      justify-content: space-between;

      .icon {
        border: 5px solid $pastal-green;
      }
    }
  }

  &__declaration {
    &-comment {
      margin-bottom: 20px;
    }
  }

  &__quarter {
    &-text {
      font-weight: $font-weight-400;
      margin-bottom: 20px;
      margin-top: 40px;

      &--value {
        font-weight: $font-weight-700;
      }

      &--description {
        font-weight: $font-weight-400;
        margin-bottom: 10px;
      }

      &--wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &--no-data {
        margin-top: 20px;
      }
    }

    &-goods {
      display: flex;
      font-weight: $font-weight-400;
      justify-content: space-between;

      &--bold {
        font-weight: $font-weight-700;
      }
    }

    &-input {
      align-items: center;
      display: flex;
      font-weight: $font-weight-400;
      justify-content: space-between;
      margin: 20px 0;

      &--input {
        margin-left: 10px;
      }

      &--wrapper {
        align-items: flex-end;
        display: flex;

        .status {
          &__step-icon {
            border-radius: 100%;
            border-width: 5px;
            bottom: 4px;
            height: 24px;
            position: relative;
            width: 24px;
          }
        }

        .icon {
          &__content {
            height: 0.875rem;
            position: unset;
            width: 0.875rem;
          }
        }
      }

      .input {
        &__label {
          font-size: $font-12;
          justify-content: flex-end;
        }
      }
    }

    &-comment {
      margin-top: 20px;
    }
  }
}
