@import "../../styles/utils";

.tvd {
  &__search {
    width: 250px;

    .input__pre-icon {
      margin: 0 12px 0 5px;
    }
  }

  &__filter-button {
    column-gap: 10px;
    display: flex;
    font-weight: $normal-font-weight;
  }

  &__create-button {
    font-weight: $boldest-font-weight;
  }

  &__modal__filter {
    .modal__container {
      min-width: 500px;
    }
  }

  &__modal {
    .modal__container {
      max-width: 473px;
    }
  }
}

.tvd-order {
  &__title {
    font-size: 14px;
    font-weight: $font-weight-700;
    line-height: 21px;
    margin-bottom: 4px;
  }

  &__sub-title {
    font-size: 14px;
    font-weight: $font-weight-400;
    margin-bottom: 16px;
  }

  &__hr {
    border-bottom: 1px solid;
    border-color: $light-lemon;
    margin-bottom: 16px;
    width: 100%;
  }

  &__type {
    margin: 16px 0;
  }

  &__radio-item {
    margin-bottom: 4px;
  }

  &__input {
    margin: 16px 0;
  }

  &__checkbox {
    margin: 8px 0;
  }

  &__actions {
    margin-top: 16px;

    button:first-child,
    a:first-child {
      margin-right: 8px;
      vertical-align: middle;
    }
  }
}
