@import "../../styles/utils";

.welcome-page {

  &__form {
    &--content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;

      .user-two-factor-configuration {

        .emphasis-tag {
          display: none;
        }
      }
    }

    .rcl-popover__parent {
      display: block;
      float: right;
      position: relative;
    }
  }

  &__horizontal-line {
    margin-top: 40px;
    opacity: 0.3;
    width: 250px;
  }

  &__registration {
    margin-top: 20px;

    &--button {
      color: $dark-green;
      cursor: pointer;
      font-weight: 700;
    }
  }
}
