@import "../../../styles/utils";

$accent-bg-color: #dfeafd;
$accent-text-color: #2463d0;

$disabled-bg-color: #efefef;
$disabled-text-color: #4d4d4d;

$success-bg-color: #e7f3e5;
$success-text-color: #407638;

$warningbg-color: #fef7da;
$warning-text-color: #856b05;

$danger-bg-color: #f8d8d7;
$danger-text-color: #b00502;

.emphasis-tag {
  align-items: center;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-flex;
  fill: $cool-gray-30;
  font-size: 1.6rem;
  font-weight: 400;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  min-width: 24px;
  padding: 1px 6px;

  svg {
    width: 1.6rem;
  }

  &__number {
    font-weight: 500;
  }

  &--dark-mode {
    background-color: rgba($cool-gray-30, 0.8);
    border: 0.1rem solid $cool-gray-30;
    color: $cool-gray-99;
    fill: $white;
  }

  &--circular {
    border-radius: 12px;
  }

  &--oval {
    border-radius: 4px;
  }

  &--accent {
    background-color: $accent-bg-color;
    border: none;
    border-radius: 4px;
    color: $accent-text-color;
    padding: 0 6px;
  }

  &--important {
    background-color: $blueish-black;
    border: none;
    border-radius: 4px;
    color: $white;
    padding: 0 6px;
  }

  &--disabled {
    background-color: $disabled-bg-color;
    border: none;
    border-radius: 4px;
    color: $disabled-text-color;
    padding: 0 6px;
  }

  &--normal {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 #00393f29;
    color: $blueish-black;
    padding: 0 6px;
  }

  &--success {
    background-color: $success-bg-color;
    border: none;
    border-radius: 4px;
    color: $success-text-color;
    padding: 0 6px;
  }

  &--warning {
    background-color: $warningbg-color;
    border: none;
    border-radius: 4px;
    color: $warning-text-color;
    padding: 0 6px;
  }

  &--danger {
    background-color: $danger-bg-color;
    border: none;
    border-radius: 4px;
    color: $danger-text-color;
    padding: 0 6px;
  }

  &--left {
    &-margin-none {
      margin-left: 0;
    }
  }

  &--medium {
    font-size: 14px;
    font-weight: $bolder-font-weight;
    height: 24px;
  }

  &--small {
    font-size: 14px;
    font-weight: $bolder-font-weight;
    height: 20px;
    padding: 2px 7px;
  }

  &--tiny {
    font-size: 12px;
    font-weight: $bolder-font-weight;
    height: 20px;
  }

  &__content {
    align-items: center;
    display: flex;
    line-height: 1rem;
    padding: 3px 0;

    &__text {
      font-size: 10px;
      letter-spacing: -0.02em;
      text-align: center;
      text-transform: capitalize;
    }

    &__category {
      margin-right: 2px;
    }

    &__icon {
      margin-right: 4px;

      &-tertiary svg {
        fill: $light-blue;
      }

      &-success svg {
        fill: $success;
      }

      &-warning svg {
        fill: $warning;
      }

      &-danger svg {
        fill: $danger;
      }

      &-inverse svg {
        fill: $white;
      }
    }
  }
}
