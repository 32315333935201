@import "../../../../../styles/utils.scss";

.plant-cultivation {
  &__method {
    &--header-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &--table-grape {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      padding-bottom: 0;

      .input {
        display: flex;

        &__label {
          margin-right: 10px;
        }
      }

      &:last-of-type {
        padding-bottom: 10px;
      }
    }

    &--outside-card {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      margin-bottom: 20px;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &--fruit-header {
      align-items: center;
      display: flex;
    }

    &--fruit-main-checkbox {
      background-color: $blueish-black-8;
      height: 60px;
      padding-left: 20px;

      &__deselected {
        background-color: $white;

        &--with-non-empty-field {
          background-color: $white;

          .rcl-checkbox__check {
            background: $blueish-black;
          }

          .rcl-checkbox__check-icon {
            display: block;
          }
        }
      }

      .rcl-checkbox {
        &__label {
          flex: 1;
          margin-right: 20px;
        }
      }
    }

    &--card {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      margin: 0 auto;
      margin-top: 20px;
      padding: 0;
      width: 96%;

      &__selected {
        border: 2px solid $blueish-black;
      }

      &:last-of-type {
        margin-bottom: 20px;
      }
    }

    &--mushroom {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 10px 10px 0;

      &:last-of-type {
        padding-bottom: 10px;
      }

      .input {
        display: flex;

        &__label {
          margin-right: 10px;
        }
      }
    }

    &--title {
      align-items: center;
      background-color: $blueish-black-8;
      border-bottom: 1px solid $blueish-black-16;
      border-radius: 10px 10px 0 0;
      display: flex;
      height: 60px;

      .icon {
        fill: transparent;
        margin-left: 10px;

        svg {
          fill: transparent;
        }
      }
    }

    &--text {
      font-weight: $font-weight-700;
      margin-left: 10px;
    }

    &--arable-crop {
      margin-bottom: 20px;
      margin-left: 20px;

      &--content {
        border: 1px solid $blueish-black-16;
        border-radius: 4px;
        margin-top: 20px;
        padding: 10px;

        &__checked {
          border: 2px solid $blueish-black;
        }
      }
    }

    &--mushroom-card {
      border: 1px solid $blueish-black-16;
      margin: 20px;
      padding: 0;
    }

    &--open {
      align-items: center;
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 0;
      padding: 10px;

      &__vegetable {
        max-width: 40%;
      }

      &__checked {
        border: 2px solid $blueish-black;
      }

      &__tooltip-label {
        bottom: 2px;
        position: relative;
      }

      &:last-of-type {
        margin-bottom: 20px;
      }

      .input {
        display: flex;

        &__label {
          margin-right: 10px;
          margin-top: 4px;
        }
      }

      .rcl-checkbox {
        &__label {
          margin-left: 15px;
        }
      }
    }

    &--open-fruit {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      justify-content: space-between;
      margin: 20px 20px 0;
      padding: 10px;

      &:last-of-type {
        margin-bottom: 20px;
      }

      &__check {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        &--high-trunk {
          margin-top: 0;
        }

        &--selected {
          border: 2px solid $blueish-black;
        }

        .input {
          display: flex;

          &__label {
            margin-right: 10px;
            margin-top: 4px;
          }
        }
      }
    }

    &--cultivation-time {
      display: flex;
      justify-content: space-between;
    }
  }

  &__header {
    font-size: 16px;
    font-weight: $font-weight-700;
  }

  &__required {
    bottom: 2px;
    font-size: $font-12;
    left: 2px;
    position: relative;
  }

  &__checkbox-fruit {
    &--covered {
      height: min-content;
      padding: 0;
    }
  }

  &__content {
    &--item {
      margin-top: 20px;
    }
  }

  &__details-text {
    font-size: $font-16;
    font-weight: $font-weight-700;
    margin-bottom: 20px;
  }

  &__produce-type {
    font-size: $font-16;
    margin-bottom: 5px;
  }

  &__tabs {
    .rtabs {
      &__nav {
        margin-bottom: 20px;
        margin-top: 0;
      }

      &__pane {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  &__herbs-checkbox {
    margin-top: 10px;
  }

  &__right-input {
    padding-left: 20px !important;
  }

  &__description {
    font-size: $font-10;
    font-weight: $font-weight-400;
    padding-top: 5px;
  }

  &__hints {
    font-size: $font-10;
    margin-left: 15px;
  }

  &__time {
    display: flex;
    position: relative;
    right: 116px;

    &--checkbox {
      margin-left: 50px;
    }

    &--text {
      margin-top: 10px;

      &__disabled {
        opacity: 0.4;
      }
    }
  }

  &__herbs {
    align-items: center;
    display: flex;

    &--title {
      margin-left: 10px;
    }
  }

  &__card {
    border: 1px solid $blueish-black-16;
    border-radius: 10px 10px 0 0;
    margin-top: 20px;
    padding: 0;
    width: 100% !important;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  &__mushroom {
    &--noble {
      &__label {
        align-items: center;
        display: flex;
      }
    }
  }
}
