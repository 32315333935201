@import "../../styles/utils.scss";

.super-office-details {
  .card {
    &__header {
      &--title {
        margin-bottom: 10px;
      }

      &-text {
        font-size: $font-16;
        font-weight: $font-weight-700;
      }
    }

    &__body-section {
      font-weight: 400;
    }
  }

  &__horizontal-line {
    border: 1px solid $cool-gray-95;
    margin: 20px 0;
  }

  &__card-subtitle {
    display: inline-block;
    font-size: $font-14;
    font-weight: $font-weight-700;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  &__company-attribute-header {
    font-size: $font-14;
    font-weight: $font-weight-700;
    text-transform: capitalize;
  }

  &--general_information {
    margin-top: 10px;
  }

  &__basic {
    display: flex;
    justify-content: space-between;
  }

  &__addresses {
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;

    &-block {
      flex: 1;
    }
  }

  &__container {
    column-gap: 20px;
    display: flex;
    flex-wrap: wrap;

    &-element {
      flex: 1;
    }
  }

  &__contacts {
    &-phone,
    &-website {
      display: flex;
      justify-content: space-between;
    }

    &-website {
      margin-top: 33px;
    }
  }

  .address {
    &__name {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__details {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }

  &__market-data,
  &__interest {
    display: flex;
    flex-wrap: wrap;

    .attribute-alignment {
      margin-bottom: 20px;
      width: 50%;
    }
  }

  &__communication-mode {
    &-language {
      margin-bottom: 33px;
    }
  }

  &__company-attributes {
    &--contact-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.attribute-alignment {
  display: flex;
  flex-direction: column;

  span {
    &:first-of-type {
      font-weight: $font-weight-700;
      margin-bottom: 5px;
    }
  }
}
