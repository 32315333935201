@import "../../../../../styles/utils.scss";

.others-data {
  &__container {
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .card__header--title {
      border-bottom: 1px solid $light-lemon;
      padding-bottom: 12px;

      .card__header-text {
        font-weight: $font-weight-700;
      }
    }
  }

  &__control-body {
    display: flex;
    justify-content: space-between;
    padding-top: 8px;

    &__value {
      text-transform: capitalize;
    }
  }
}
