@import "../../styles/utils";

.rcl-stacked-items {
  &__display-items,
  &__list {
    .organization-name {
      color: $blueish-black;
      display: block;
      font-size: 14px;
      text-transform: none;
    }
  }
}

.organisation {
  &--deactivated {
    pointer-events: none;

    .link,
    .button,
    .icon-button {
      opacity: 0.4;
    }
  }

  &__change-address {
    &--request {
      font-size: $font-14;
      font-weight: $font-weight-700;
      margin-bottom: 5px;
    }

    &--description {
      font-size: $font-10;
      font-weight: $font-weight-400;
      margin-bottom: 20px;
    }

    &--new {
      font-weight: $font-weight-700;
    }

    &--current {
      font-weight: $font-weight-400;
      margin: 5px 0 20px;
    }
  }

  &__history-btn {
    margin-top: 20px;

    .icon {
      position: relative;
      top: 2px;
    }
  }

  &__popover {
    font-size: 14px;
    font-weight: 400;

    .organization-name {
      color: $info;
    }

    &-wrapper {
      .rcl-popover {
        &__content {
          padding: 0;
        }
      }
    }

    &-content {
      padding: 12px 4px;

      &:hover {
        background-color: $blueish-black-8;
        cursor: pointer;
        opacity: 1;
      }

      &:active {
        background-color: $blueish-black-16;
        font-weight: $font-weight-700;
      }
    }
  }

  &__security-modal {
    &--first-description {
      margin-bottom: 20px;
      text-align: left;
    }

    &--second-description {
      margin-bottom: 10px;
      text-align: left;
    }

    &--checkbox {
      align-items: flex-start;

      .rcl-checkbox {
        &__check {
          margin-top: 4px;
        }

        &__label {
          text-align: left;
        }
      }
    }
  }

  &__list {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    &-count {
      color: $blueish-black;
      margin-left: 20px;
    }
  }

  &__inactive-btn {
    background-color: $normal-red;
  }

  &__head {
    &-go-back {
      margin-left: 8px;
    }

    &-popover {
      &--go-back {
        background-color: white;
        border: 1px solid #00393f29;
        border-radius: 4px;
        height: 2.125rem;
        padding: 0;
        width: 2.125rem;

        &:hover {
          background-color: $blueish-black-16;
        }
      }

      &--content {
        cursor: pointer;
        font-size: $font-14;
        padding: 12px 0;

        &__text {
          color: $blueish-black;
          padding-left: 8px;
        }

        &:hover {
          background-color: $blueish-black-8;
        }

        &:active {
          background-color: $blueish-black-16;
          font-weight: $font-weight-700;
        }
      }

      &--wrapper {
        .rcl-popover {
          &__content {
            padding: 0;
          }
        }
      }

      &--icon {
        border: 1px solid #00393f29;
        border-radius: 4px;

        &-button {
          justify-content: flex-start;
          margin-left: 0;
          padding: 0;
        }

        svg {
          font-size: 2rem;
        }

        :hover {
          rect {
            fill: $blueish-black-24;
          }
        }
      }
    }
  }

  &-contract {
    &__buttons {
      &-cancel {
        margin-left: 10px;
      }
    }

    &__terminate-icon {
      .icon {
        margin-top: 4px;
      }
    }

    &__reason {
      margin: 20px 0;
    }

    &__wrapper {
      .card {
        &--v2 {
          &__empty-card {
            align-items: center;
            justify-content: center;
            min-height: 533px;
          }
        }
      }
    }

    &__step-one {
      margin-bottom: 5px;
    }

    &__terminate-text {
      display: flex;
    }

    &__remaining-days {
      bottom: 10px;
      position: relative;

      &--success {
        color: #407638;
        font-weight: $font-weight-700;
      }

      &--warning {
        color: $warning-semi-dark;
        font-weight: $font-weight-700;
      }

      &--danger {
        color: #b00502;
        font-weight: $font-weight-700;
      }

      &--text {
        bottom: 10px;
        margin-left: 4px;
        position: relative;
      }

      &--wrapper {
        position: relative;
        top: 2px;
      }

      .icon-button {
        position: relative;
        top: 2px;
      }
    }

    &__tooltip--list {
      font-size: $font-14;
      margin: 0;
      width: 400px;
    }

    &__date-wrapper {
      display: flex;
      margin: 20px 0 10px 0;

      .rcl-popover__parent {
        bottom: 8px;
        position: relative;
      }
    }

    &__step-download {
      margin: 5px 0;
    }

    &__step-three {
      .card {
        margin-top: 5px;
      }

      .rcl-alert-container {
        flex-direction: row;

        &__content {
          width: 100%;
        }
      }

      .choose-file {
        &__contract-footer {
          margin-top: 20px;
        }
      }

      .rcl-alert__neutral {
        svg {
          fill: $blueish-black;
        }
      }

      .rcl-alert__warning-medium {
        svg {
          fill: #f7ca18;
        }
      }
    }

    &__first-step,
    &__second-step {
      display: flex;
      font-size: $font-16;
      font-weight: $font-weight-700;
      margin-bottom: 10px;

      &--tag {
        .emphasis-tag__content__text {
          text-transform: none;
        }

        .supplier-certificates {
          &__row {
            &-header-complain {
              font-size: $font-14;
              font-weight: $font-weight-400;
            }
          }
        }
      }
    }

    &__save {
      margin-top: 20px;
    }

    &__eligibility {
      margin-bottom: 20px;
    }

    &__declare-btn {
      margin-top: 20px;
    }

    &__verify-btn {
      display: flex;
      margin-top: 20px;
    }

    &__delete-btn {
      margin-left: 10px;
    }

    &__complain-btn {
      margin-left: 10px;
    }

    &__success {
      margin-left: 10px;
    }

    &__download {
      align-items: center;
      font-weight: $font-weight-700;
      justify-content: space-between;
      margin-bottom: 20px;
      &-producer {
        &--file {
          display: flex;
        }
      }

      &-file {
        display: flex;
        margin-bottom: 20px;

        &--seperator {
          background-color: $blueish-black-16;
          height: 20px;
          margin: 0 10px;
          width: 2px;
        }

        &--icon {
          height: 21px;
          width: 16px;
        }
      }
    }

    &__address {
      align-items: center;
      display: flex;
      font-weight: $font-weight-400;
      margin-bottom: 15px;

      &-wrapper {
        font-size: $font-14;
        font-weight: $font-weight-700;
        margin-bottom: 12px;
      }

      &-text {
        margin-left: 10px;
      }

      .icon {
        font-size: 1rem;
      }
    }

    &__upload {
      margin-left: 5px;
    }

    &-footer {
      font-size: $font-weight-700;
    }
  }

  &-image__wrapper {
    margin-bottom: 20px;

    .rcl-avatar {
      &__content {
        height: 120px;
        width: 120px;
      }
    }

    &-edit {
      .icon-button {
        bottom: 80px;
        position: relative;
        right: 20px;
      }
    }
  }

  &__pagination {
    padding-bottom: 20px;
    padding-right: 20px;
  }

  &__card {
    margin-left: 20px;
    &--wrapper {
      display: flex;
      justify-content: space-between;
    }

    &--icon {
      margin-left: 6px;
    }

    &--certificate {
      align-items: center;
      display: flex;
      font-size: 16px;
      font-weight: 900;

      .icon {
        svg {
          fill: $green;
        }
      }
    }

    &--due-date {
      font-size: 14px;
    }

    &--status {
      font-size: 10px;
    }
  }

  &__popover {
    padding: 20px;

    &--not-acknowledged {
      margin-top: 20px;
    }
  }

  &__create-button {
    padding-right: 20px;
  }

  &__search-tab {
    column-gap: 10px;
    display: flex;

    &--wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }

    &__filter-button {
      padding: 0 8px;
    }
  }

  &__search {
    width: 250px;

    .input__pre-icon {
      margin: 0 12px 0 5px;
    }
  }

  &__filter-button {
    align-items: center;
    column-gap: 10px;
    display: flex;
    font-weight: $font-weight-600;

    .badge__number {
      font-size: 14px;
      font-weight: $font-weight-600;
      padding: 0px 9px;
    }
  }

  &__rtabs {
    margin: 30px 0 10px 20px;
    padding-right: 20px;

    .rtabs__nav-item--text {
      margin: 0;
    }
  }

  &-details {
    &__contract {
      align-items: center;

      &-disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      &--warning {
        align-items: center;
        background-color: $white;
        border: $alert-list-border;
        border-radius: 4px;
        padding: 10px;
        width: fit-content;
      }

      &-declare {
        &--wrapper {
          margin-left: 10px;
        }

        &--text {
          color: $blueish-black;
          font-size: $font-14;
          font-weight: $font-weight-700;
          margin-right: 5px;
        }

        &--footer-text {
          color: $blueish-black;
          font-size: $font-14;
          font-weight: $font-weight-700;
        }

        &--request {
          color: $blueish-black;
          font-size: $font-14;
          margin-right: 5px;
        }
      }
    }
    &__wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .icon-button {
        &__icon {
          font-size: 2rem;
        }
      }
    }

    &__head {
      align-items: center;
      display: flex;
      height: 80px;
      justify-content: space-between;
      margin-top: 20px;

      .icon-button {
        height: 2rem;
        width: 2rem;
      }

      &__side {
        display: flex;
        margin-left: 5px;

        &--right-arrorw {
          margin-left: 5px;
        }

        .icon-button {
          padding: 0;
          width: fit-content;
        }
      }

      &-name-icon {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      &--image {
        margin-left: 20px;

        &-details {
          margin-left: 0;
        }
      }

      &--title {
        align-items: center;
        color: $blueish-black;
        display: flex;
        justify-content: space-between;

        &__vertical-line {
          background-color: $blueish-black-16;
          height: 42px;
          margin-left: 12px;
          margin-right: 10px;
          width: 1px;
        }

        &__id {
          &-wrapper {
            align-items: center;
            color: $blueish-black;
            display: flex;
            margin-left: 12px;
            margin-top: 5px;
          }

          &-bio,
          &-uid {
            font-size: $font-14;
          }

          &-vertical-line {
            background-color: $blueish-black-16;
            height: 17px;
            margin-left: 10px;
            margin-right: 10px;
            width: 1px;
          }
        }

        &__main {
          font-size: 20px;
          font-style: normal;
          font-weight: $font-weight-700;
          line-height: 24px;
          margin-left: 12px;
          max-width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &-address {
            color: $blueish-black;
            font-size: $font-14;
            margin-left: 12px;
            margin-top: 5px;
          }
        }

        &__subtitle {
          font-size: $font-12;
          font-style: normal;
          font-weight: $font-weight-200;
          line-height: 17px;
        }
      }

      &--address {
        margin: auto;

        &__with-icon {
          display: flex;
        }

        &__icon {
          margin-right: 12px;
          .icon {
            svg {
              fill: $black;
            }
          }
        }

        &__info {
          font-size: 12px;
        }
      }

      &--view {
        cursor: pointer;
        font-size: 12px;
        margin-right: 30px;
      }

      .rcl-popover {
        &__parent {
          position: relative;
          top: 2px;
        }
      }
    }

    &__rtabs {
      padding: 0 20px;

      .rtabs {
        &__nav {
          margin-top: 0;

          &-item {
            font-size: 14px;
          }
        }

        &__pane {
          padding: 20px 0;
        }
      }
    }

    &__view-btn {
      align-items: center;
      display: flex;
      justify-content: space-between;

      &--wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .icon {
        &__content {
          background-color: $warning;

          svg {
            fill: $white;
          }
        }
      }
    }
  }

  &-overview {
    &__component-wrapper {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 8fr 4fr;
    }

    &__card {
      margin-bottom: 20px;
    }

    &__card-title {
      display: inline-block;
      font-size: $font-16;
      font-weight: $font-weight-700;
      margin-bottom: 10px;
    }

    &__card-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
    }

    &__card-wrapper:first-of-type > :first-child {
      grid-column: span 2;
    }

    &__card-wrapper:last-of-type {
      grid-template-columns: repeat(2, 1fr);
    }

    &__content {
      display: flex;
      flex-direction: column;
      font-size: $font-14;
      margin-bottom: 20px;
      row-gap: 5px;

      &--margin-none {
        margin-bottom: 0;
      }

      &--margin-top {
        margin-top: 20px;
      }

      &-wrapper {
        display: grid;
        grid-gap: 0 15px;
        grid-template-columns: repeat(3, 1fr);
        text-transform: capitalize;
      }

      &-label {
        font-weight: $font-weight-700;
      }

      &-value {
        font-weight: $font-weight-400;

        &--disabled {
          color: $disabled;
        }
      }

      &--button {
        margin-top: 10px;
      }
    }

    &__contact-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__latest-anerkennung {
      color: $light-blue;
      cursor: pointer;
      text-decoration: none;
      text-transform: capitalize;
      white-space: nowrap;
    }

    &__contact-user {
      align-items: flex-start;
      display: flex;
      margin-top: 10px;

      &--details {
        display: flex;
        flex-direction: column;
        font-weight: $font-weight-400;
        margin: 3px 10px;
        text-transform: capitalize;
      }

      &__user-role {
        font-size: $font-10;
      }
    }

    &__header {
      font-size: $font-14;
      font-weight: $font-weight-700;
    }

    &__communication {
      min-height: 215px;
    }

    &__text {
      margin-top: 5px;

      &-link {
        color: $info;
      }
    }

    &__address {
      &-header {
        font-weight: $font-weight-700;
        margin-bottom: 10px;
      }

      &-wrapper {
        padding: 5px 15px 20px 15px;

        &--last {
          padding: 0 15px 20px 15px;
        }
      }

      &-data {
        font-weight: $font-weight-400;
      }
    }

    &__content {
      &-header {
        font-weight: $font-weight-700;
        padding: 0;
      }
      .empty-placeholder {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
      }
    }
  }

  &__view {
    &-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      &__title-logo {
        align-items: center;
        display: flex;
        justify-content: flex-start;
      }

      &__logo {
        margin-right: 10px;
      }

      &__loader {
        margin-right: 16px;
      }

      &--with-popover {
        align-items: center;
        display: flex;
      }
    }

    &-edit-button {
      margin-left: 10px;
    }

    &-organisation-name {
      font-size: 20px;

      .organization-name {
        font-weight: $font-weight-700;
        margin-left: 12px;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon-button {
        margin-top: 4px;
      }
    }

    &-organisation-description {
      color: $blueish-black;
      font-size: $font-12;
      font-weight: 200;

      &--loader {
        display: flex;
        width: 500px;
      }
    }
  }

  &__bio-cuisine-status {
    display: flex;

    &--icon {
      svg {
        fill: $green !important;
      }
    }

    &--downgraded {
      svg {
        fill: $disabled-light !important;
      }
    }

    .icon-button {
      height: 1.75rem;
      width: 1.75rem;
    }

    svg {
      font-size: 1.125rem;
    }
  }

  &__table {
    &-row {
      &--name {
        cursor: pointer;
      }

      &--violet-color {
        color: $info;
      }

      &--sub-organizations {
        display: flex;
        flex-flow: column;

        span {
          margin-bottom: 22px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-multiple {
          cursor: pointer;
        }

        .organization-name {
          font-size: $font-14;
        }
      }

      &-health {
        align-items: center;
        display: flex;

        &--margin-top {
          margin-top: 20px;
        }

        &__popover {
          color: $primary-text;
          font-size: $font-14;
        }

        .emphasis-tag {
          white-space: nowrap;
        }
      }

      &--members {
        white-space: nowrap;
      }

      .emphasis-tag {
        margin: 0;
      }
    }
  }

  &__form {
    padding: 30px 35px;

    &--tvd {
      .select-box-wrapper,
      .input {
        width: 50%;
      }
    }

    &-container {
      display: flex;
      justify-content: center;
      padding: 20px 0;

      .rtabs__nav-button-container {
        height: 100%;
      }

      &--no-shadow {
        .card {
          box-shadow: none;
        }
      }
    }

    &-align {
      display: flex;
    }

    &-type {
      font-weight: $font-weight-700;
    }

    &--parent-select {
      width: 50%;
    }

    &--default-color {
      color: $blueish-black;
    }

    &--smaller-font {
      font-size: $font-12;
    }

    &-contacts {
      &-placeholder {
        color: $blueish-black;
        font-size: $font-12;
        margin-top: 5px;
      }
    }

    &-suborganisation {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;

      &--wrapper {
        :first-child {
          .organisation__form--margin-top {
            margin-top: 12px;
          }
        }
      }

      &--input {
        width: 50%;

        &__label {
          align-items: flex-end;
          display: flex;
          justify-content: center;

          .spinner {
            &--small {
              padding-bottom: 4px;
              svg {
                width: 1rem;
              }
            }
          }
        }
      }

      &--icon {
        margin-left: 5px;

        .icon {
          font-size: 0.625rem;

          svg {
            fill: $blueish-black-24;
          }
        }
      }
    }

    &--radio {
      display: flex;
      flex-direction: column;

      &__item {
        font-weight: $font-weight-400;
        padding-bottom: 15px;
      }
    }

    &-sub-label {
      font-size: 10px;
      font-weight: $bolder-font-weight;
      line-height: 14px;

      &--color {
        color: $info;
        text-decoration: none;
      }
    }

    &--disabled {
      color: $disabled;
    }

    &--margin-top {
      margin-top: 20px;
    }

    &--help-text {
      &-margin-top {
        margin-top: 10px;
      }
    }

    &--padding-right {
      padding-right: 10px;
    }

    &--padding-left {
      padding-left: 10px;
    }

    &-organisation-name {
      padding-left: 10px;
    }

    &-registered-name {
      padding-right: 10px;
    }

    &--font-bold {
      font-weight: $font-weight-700;
    }

    &-action {
      a {
        margin-right: 10px;
      }
    }

    &-address {
      &-flex {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
      }

      &-zip-code {
        flex: 30%;
      }

      &-canton {
        flex: 30%;
      }

      &-country {
        flex: 30%;
      }
    }

    &-modal {
      .modal {
        &__container {
          max-width: fit-content;
        }

        &__content {
          justify-content: center;
          text-align: center;
          white-space: pre-wrap;
        }

        &__title {
          align-items: center;
          flex-flow: column;
          justify-content: center;
        }
      }

      &--uid {
        align-items: center;
        display: flex;

        .input {
          width: 50%;
        }

        &-spinner {
          margin-left: 10px;
          margin-top: 30px;

          .spinner {
            padding: 0;
          }
        }

        &-position {
          align-items: center;
          display: flex;
          justify-content: center;

          &__icon {
            .icon-button {
              padding: 5px;

              &--tiny {
                height: 1.5rem;
                width: 1.5rem;
              }
            }
          }
        }
      }

      &--title {
        font-size: 16px;
        font-weight: $font-weight-700;
        margin: 10px 0;
      }
    }

    &-receive-invoice {
      margin-top: 12px;
    }

    &-stepper-actions {
      button {
        margin-right: 10px;
      }
    }

    &--main-card {
      width: 770px;
    }

    .card {
      &__body-section {
        font-weight: 400;
      }
    }

    &-languages {
      column-gap: 20px;
      display: flex;

      .select-box {
        width: 50%;
      }
    }

    &-contact {
      column-gap: 20px;
      display: flex;

      &-field {
        flex: 1;
      }
    }

    &-preferred-contact {
      display: flex;
      flex-direction: column;

      > div {
        margin-top: 8px;
      }

      .rcl-radio {
        margin-top: 8px;

        &__label {
          font-size: 14px;
        }
      }
    }

    &-control-body {
      .select-box {
        width: 50%;
      }
    }

    &-primary-member-input {
      width: 50%;
    }

    &-control-body-radio {
      display: flex;
      flex-direction: row;

      > div {
        margin-top: 8px;
      }
    }
  }

  &-charta {
    &--margin-top {
      margin-top: 20px;
    }

    &__title {
      font-size: 16px;
      font-weight: $boldest-font-weight;
    }

    &__placeholder {
      margin-bottom: 10px;
      margin-top: 30px;
      width: 70%;
    }

    &__create {
      .card__body-section {
        display: flex;
        flex-flow: column;
      }

      &-action {
        margin-top: 10px;
      }
    }

    &__items {
      &-item {
        align-items: flex-start;
        display: flex;
        margin-top: 20px;

        &-details {
          &-title {
            font-weight: $boldest-font-weight;
          }

          &-description {
            font-weight: $bolder-font-weight;
            line-height: 1.5;
            margin-bottom: 10px;
          }
        }
      }
    }

    &__modal {
      &-body {
        display: flex;
        flex-flow: column;

        &-proceed {
          margin-top: 10px;
        }
      }
    }

    &__validity {
      display: flex;
      flex-flow: column;

      &-time {
        display: flex;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;

    .icon {
      svg {
        fill: $dark-blue;
      }
    }
  }

  &__link {
    color: $light-blue;
    text-decoration: none;
  }

  &-create {
    &__modal {
      &-content {
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 40px;

        > * {
          margin-right: 20px;
        }

        &-type {
          align-items: center;
          border: 1px solid $dark-green;
          border-radius: 10px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 149px;
          justify-content: center;
          width: 150px;

          &--selected {
            background: rgba($blueish-black, 0.08%);
          }
        }
      }

      &-title {
        display: flex;
        flex-direction: column;
        text-align: center;

        span {
          &:first-of-type {
            font-size: 16px;
            font-weight: 700;
          }
        }

        span {
          &:last-of-type {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }

    &--public {
      left: 0;
      margin-top: 20px;
      position: absolute;
      right: 0;
    }
  }

  &__public {
    margin-top: 120px;
    min-width: 45%;

    &--loader {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &--create-button {
      width: 100%;
    }

    &--horizontal-line {
      height: 0;
      margin-left: 4px;
      margin-top: 30px;
      opacity: 0.3;
      width: 30%;

      &__text {
        margin-left: 14px;
        white-space: nowrap;
        width: 40%;
      }
    }

    &--list {
      border: 1px solid $blueish-black-16;
    }

    &-header {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 10;
    }
  }

  &__sync-single {
    margin-right: 10px;
  }

  &__sync-all {
    margin-right: 10px;
  }
}

.organization {
  &-name {
    color: $blueish-black;
  }
}
.invoice {
  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;

    .card {
      padding: 20px;
    }

    > div:first-child {
      margin: 20px 0;
    }

    > *:first-child {
      width: 640px;
    }

    &-cost-center {
      display: flex;
      gap: 20px;
      margin-top: 20px;
    }
  }

  &__form_label {
    font-size: 16px;
    font-weight: 700;
  }

  &__item {
    font-size: 16px;
    font-weight: $font-weight-700;

    &--wrapper {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
    }

    &--table {
      padding: 0;
    }

    &-details {
      display: flex;
      gap: 20px;

      > *:first-child {
        width: 50%;
      }
    }
  }

  &__form-button {
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    margin: 20px 0;
  }

  &__form-input {
    &--right {
      padding-left: 20px !important;
    }
  }

  &__form-submit {
    column-gap: 10px;
    display: flex;
    flex-direction: row;
  }

  &__divider {
    border: 1px solid rgba($disabled, 0.2);
    margin-top: 20px;
    width: 100%;
  }

  &__line_items {
    font-weight: bold;
  }

  &__date-picker {
    height: 32px;
  }

  &__date-wrapper {
    margin-bottom: 20px;
  }
}
