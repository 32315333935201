@import "../../../../styles/utils.scss";

.operations {
  &__farmland,
  &__production {
    display: flex;

    &--content {
      flex: 1;
      margin: 10px 10px 0 0;
      max-height: 330px;
      overflow: auto;
    }

    &--meat-title {
      min-width: 50%;
    }

    &__inside-card {
      border: 1px solid $blueish-black-16;
      box-shadow: none;
      margin: 20px 0 0;
      padding: 20px;
    }

    &__fruit-content {
      border: 1px solid $blueish-black-16;
      box-shadow: none;
      margin: 20px 0 0;
      padding: 10px;
    }

    &__fruit-content-title {
      font-weight: $font-weight-700;
      margin-bottom: 10px;
    }

    &__fruit-content-card {
      margin-top: 30px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    &--card {
      margin-right: 10px;
      width: 32.6%;
    }

    &--last {
      display: flex;
      width: 67%;
    }

    &--multiple {
      margin-right: 10px;
      width: 32.6%;
    }
  }

  &__main-card {
    .rtabs {
      &__nav-item {
        background: none;

        &--active {
          background: none;
          border-bottom: 1px solid $blueish-black;
          border-radius: 0px;
          color: $blueish-black;
        }
      }
    }
  }

  &__empty-card {
    .card {
      height: 100%;
    }
  }

  &__plant {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 1 calc(33.33% - 20px);
    }
  }
}
