@import "../../styles/utils";

.topnav {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;

  &__task-tab {
    padding-left: 4px;
  }

  &__sidepanel {
    &--task,
    &--message {
      .side-panel {
        &__header {
          margin-bottom: 0;
        }
      }
    }
  }

  &__avatar {
    .icon-button {
      border-radius: 50%;
      bottom: 60px;
      left: 36px;
      position: relative;
    }
  }

  &__user,
  &__login {
    cursor: pointer;
  }

  &__message {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    &-avatar {
      align-items: center;
      display: flex;

      &--title {
        margin-left: 10px;
      }
    }

    &-time {
      color: $disabled;
      font-size: 12px;
      margin-left: 42px;
    }
  }

  &__task {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    &-tag {
      display: flex;
      font-size: $font-12;
      justify-content: center;

      &--due-date {
        margin-left: 10px;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      font-weight: $font-weight-700;
      justify-content: space-between;
    }

    &-body {
      font-size: $font-12;
      margin-top: 8px;
    }

    &-horizontal {
      color: $blueish-black;
      opacity: 0.1;
    }

    &-view {
      color: $info;
      cursor: pointer;
      font-size: $font-14;
      text-align: center;
    }

    .emphasis-tag {
      margin-left: 0;
    }

    .icon-button {
      align-items: flex-start;
      padding: 0;
    }
  }

  &__user {
    &-popover {

      &--horizontal-line {
        margin-top: 20px;
        opacity: 0.2;
      }

      &--footer {
        color: $info;
        display: flex;
        font-size: 12px;
        font-weight: $font-weight-500;
        justify-content: space-between;

        &__faq,
        &__terms {
          cursor: pointer;
        }
      }
    }
  }

  &__test-tag {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;

    .emphasis-tag__content {
      &__text {
        font-size: 32px;
        font-weight: $boldest-font-weight;
      }
    }
  }
}

[class~="topnav__task-horizontal--message"]:last-of-type {
  display: none;
}
