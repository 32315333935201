@import "../../../styles/utils";

@mixin set-type-based-colors-alert-elements(
  $background-color,
  $text-color,
  $icon-button-color-on-hover,
  $icon-color
) {
  background-color: $background-color;
  color: $text-color;

  &--no-background {
    background-color: transparent;
  }

  .rcl-alert {
    &-container {
      justify-content: center;
      
      &__header {
        .icon-button {
          .icon {
            svg {
              fill: $text-color;
            }
          }

          &:hover {
            background-color: $icon-button-color-on-hover;
          }
        }
      }
    }

    &__info-icon {
      height: 1.5rem;
      margin-right: 12px;

      svg {
        fill: $icon-color;
      }
    }
  }
}

.rcl-alert {
  align-items: flex-start;
  border-radius: 3px;
  display: flex;
  font-size: $rcl-font-14;
  padding: 16px;

  &__warning {
    @include set-type-based-colors-alert-elements(
      $warning,
      $warning-dark,
      $warning-dark-16,
      $warning-dark
    );
  }

  &__warning-light {
    @include set-type-based-colors-alert-elements(
      $warning-16,
      $warning-dark,
      $warning-dark-8,
      $yellow-orange
    );
  }

  &__warning-medium {
    @include set-type-based-colors-alert-elements(
      $warning-medium,
      $warning-semi-dark,
      $warning-dark-8,
      $yellow-orange
    );
  }

  &__danger {
    @include set-type-based-colors-alert-elements($danger, $white, $white-16, $white);
  }

  &__danger-light {
    @include set-type-based-colors-alert-elements(
      $danger-16,
      $danger-dark,
      $danger-dark-8,
      $sunset-orange
    );
  }

  &__danger-outline {
    @include set-type-based-colors-alert-elements(
      $danger-outline,
      $danger-outline-text,
      $danger-dark-8,
      $danger-outline-icon
    );
  }

  &__success {
    @include set-type-based-colors-alert-elements($success, $white, $white-16, $white);
  }

  &__success-light {
    @include set-type-based-colors-alert-elements(
      $success-16,
      $success-dark,
      $success-dark-8,
      $green-cyan
    );
  }

  &__neutral {
    @include set-type-based-colors-alert-elements(
      $white,
      $primary-text,
      $primary-text-light,
      $tertiary-accent
    );
  }

  &-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    &--no-title {
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;

      .rcl-alert-container {
        &__header {
          align-self: flex-start;
          margin-bottom: 0;
        }
      }
    }

    &__header {
      align-items: center;
      display: flex;
      height: 1.5rem;
      justify-content: space-between;
      margin-bottom: 8px;

      &--close-button {
        margin-left: auto;
      }

      &--title {
        font-size: $rcl-font-14;
        font-weight: $boldest-font-weight;
        line-height: $heading-line-height;
        white-space: normal;
      }
    }

    &__content {
      line-height: $base-line-height;
    }
  }
}
