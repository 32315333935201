.portal__layout {
  padding: 0 20px;
}

.baseline-with-space-between {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.align-horizontically {
  display: flex;
}

.boldest-font {
  font-weight: 700;
}

.flex {
  display: flex;
}

.margin-right-8 {
  margin-right: 8px;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.font-size-10 {
  font-size: 10px;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.width-50-percent {
  width: 50%;
}

.align-center-with-space-between {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.font-14 {
  font-size: 14px;
}

.font-20 {
  font-size: 20px;
}
