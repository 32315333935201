@import "../../styles/utils.scss";

.control-body {
  &__form {
    &-time {
      &--wrapper {
        display: flex;
      }
    }
  }

  &__producer {
    &--title {
      color: $blueish-black;
      font-size: $font-14;
      font-weight: $font-weight-700;
      padding: 0 20px;
    }

    &--name {
      background-color: #fff;
      color: $blueish-black;
      font-size: $font-14;
      margin-top: 10px;
      padding: 10px 20px;
    }
  }

  &__disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__manage {
    font-size: $font-14;
  }

  &__complain {
    &-description {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 100%;

      &--header {
        font-size: 14px;
        font-weight: $font-weight-700;
      }

      &--body {
        font-size: 14px;
        font-weight: $font-weight-400;
      }
    }
  }

  &__action {
    align-items: center;
    display: flex;

    &-text {
      font-size: 14px;
      font-weight: $font-weight-400;
      margin-right: 12px;
    }

    &-raise {
      margin-right: 8px;
    }
  }

  &__remaining-time {
    font-size: 12px;
    font-weight: 700;

    &--day {
      font-size: 20px;
      font-weight: 200;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .organisation {
      &__bio-cuisine-status {
        align-items: center;
      }
    }
  }

  &__current-period {
    color: $blueish-black;
    font-size: 16px;
    font-weight: 400;
    margin-top: 5px;

    &--date {
      font-weight: 700;
    }

    &--wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__deadline {
    color: $blueish-black;
    font-size: $font-16;
    font-weight: $font-weight-400;
    margin-top: 5px;

    &--date {
      font-weight: 700;
    }
  }

  &-form {
    &__select {
      &-month {
        font-size: 12px;
        font-weight: 200;
      }
      &-good {
        font-size: 12px;
        font-weight: 700;
        margin-top: 5px;
      }
    }

    &__month-picker {
      margin-top: 20px;
    }

    &__buttons {
      margin-top: 5px;

      &-cancel {
        margin-left: 10px;
      }
    }
  }

  &__card {
    &-edit-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-bio-certified {
      font-weight: 600;
      margin-top: 20px;

      &--date {
        font-size: 20px;
      }
    }
  }

  &__progressbar {
    display: flex;

    .organisation {
      &__bio-cuisine-status {
        margin: 0 10px;

        .icon {
          svg {
            fill: $green;
          }
        }
      }
    }

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 8px;
    }

    &--percentage {
      text-align: right;
    }
  }

  &__average {
    &-title {
      font-size: 16px;
      font-weight: 700;
    }

    &-header {
      font-size: 12px;
      font-weight: 400;
      margin-top: 20px;
    }

    &-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &-certified,
    &-buds {
      font-size: 12px;
      font-weight: 700;

      &--percentage {
        font-size: 20px;
        font-weight: 200;
        margin-left: 5px;
      }
    }

    &-suggestion {
      &--wrapper {
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
      }

      &--star {
        font-size: 12px;
        font-weight: 700;
        margin-right: 10px;

        &--percentage {
          margin-right: 10px;

          &__disabled {
            opacity: 0.3;
          }
        }
      }

      &--text {
        font-size: 12px;
        margin-top: 25px;
      }

      &--header {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 700;
        justify-content: space-around;
        min-height: 52px;
      }

      &--body {
        font-size: 20px;
        font-weight: 200;
      }
    }
  }

  &__view-complain {
    cursor: pointer;
    font-size: 14px;
    font-weight: $font-weight-600;
    margin-left: 10px;
  }

  &__begin-button {
    padding-top: 10px;
  }

  &__history {
    &-item {
      margin-bottom: 32px;
    }

    &--time-period {
      font-weight: $font-weight-700;
    }

    &--title {
      width: 70%;
    }
  }
}

.control-flow {
  &__overview {
    font-weight: $font-weight-700;
    margin: 20px 0;

    &-card {
      box-shadow: 0 1px 3px 0 rgba(87, 7, 51, 0.12);
      height: 293px;
      margin-top: 12px;

      &--shrink {
        width: 50%;
      }

      &--admin-user {
        height: 200px;
      }
    }
  }

  &__related-files {
    &--header {
      font-size: $font-16;
      font-weight: $font-weight-700;
    }

    &--table {
      .table__row {
        border-top: none;

        &:not(:last-child) {
          border-bottom: 2px solid rgba($color: $blueish-black, $alpha: 0.08);
        }
      }
      .table__field {
        border-top: none;
        padding: 6px 0;
      }
    }
  }

  &__time-picker {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  &__icon {
    text-align: right;
    .icon-button {
      display: block;
      padding-top: 6px;
    }

    &-couple {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__upcoming-control {
    font-weight: $font-weight-700;
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;

    &-control {
      width: 100%;

      &--user {
        width: 100%;
      }

      &--remaining-days {
        color: $disabled;
        margin-left: 10px;
      }

      &-action {
        margin-top: 20px;
      }
    }

    &-report {
      width: 100%;

      .choose-file {
        &__contract-footer {
          justify-content: flex-end;
        }
      }

      &--last {
        bottom: 20px;
        position: relative;
      }
    }

    &-status,
    &-date,
    &-remaining {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      &--label {
        color: $blueish-black-40;
        font-weight: $font-weight-400;
      }

      &--value {
        font-weight: $font-weight-400;

        &__complete {
          font-weight: $font-weight-700;
        }

        &__tag {
          display: flex;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__btn {
    &-change-date {
      margin-right: 10px;
    }
  }

  &__seperator {
    background-color: $blueish-black-16;
    margin: 0 40px;
    width: 2px;

    &-upcoming {
      height: 160px;
    }

    &-latest {
      bottom: 16px;
      height: 124px;
      position: relative;
    }
  }

  &__set-control {
    &--header {
      font-size: $font-16;
      font-weight: $font-weight-700;
      margin-bottom: 20px;
    }

    &--description {
      font-weight: $font-weight-400;
      margin-bottom: 20px;
    }
  }
}
