@import "../../../../../styles/utils.scss";

.legal-form {
  &__input-field {
    padding-left: 20px !important;
  }

  &__required-field {
    color: $danger;
    font-size: $font-10;
    margin: 10px 0;
  }

  &__contact-radio {
    display: flex;
  }

  &__permit-checkbox {
    align-items: flex-start;
    margin-top: 10px;

    .rcl-checkbox {
      &__check {
        margin-top: 2px;
      }

      &__label {
        &--header {
          font-size: $font-12;
        }
      }
    }
  }

  &__operation-manage {
    &--checkbox {
      display: flex;
      flex-wrap: wrap;

      label {
        min-width: 108px;
      }

      &-item {
        margin-bottom: 20px;
        margin-right: 20px;
        min-width: 30%;
      }
    }
  }

  &__radio-button {
    display: flex;
    flex-wrap: wrap;

    &--item {
      margin-top: 10px;
      min-width: 33.33%;
    }

    &--second-row {
      margin-top: 10px;
    }

    .farmland {
      &__checkbox {
        border: none;
        box-shadow: none;
        padding: 0;
      }
    }
  }

  &__card {
    border: 1px solid $blueish-black-16;
    border-radius: 4px;
    box-shadow: none;
    margin: 20px 0;

    &--firstname {
      padding: 0 20px !important;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &--header {
      font-size: $font-16;
      font-weight: $font-weight-700;
      margin-bottom: 20px;
    }

    .card {
      &__body-section {
        .legal-form {
          &__operation-manage {
            &:nth-of-type(2) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &__email {
    margin-top: 20px;
  }

  &__input-field {
    padding-left: 20px !important;
  }

  &__title {
    font-weight: $font-weight-700;
    margin-bottom: 20px;
  }

  &__contract,
  &__details {
    &--title {
      font-weight: $font-weight-700;
      margin-bottom: 5px;
    }

    &--description {
      font-size: $font-10;
      margin-bottom: 20px;
    }
  }

  &__details {
    &--card {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      box-shadow: none;
      margin: 20px 0;

      &-empty {
        .legal-form {
          &__operation-manage {
            margin-bottom: 0;
          }
        }
      }
    }

    &--title {
      font-weight: $font-weight-700;
      margin-bottom: 5px;
    }

    &--description {
      font-size: $font-10;
    }

    &--no-data {
      border: 1px solid $blueish-black-16;
      border-radius: 4px;
      color: $blueish-black-40;
      padding: 20px;
    }
  }

  &__company {
    &--title {
      display: flex;
      font-weight: $font-weight-700;
      margin-top: 20px;
    }
  }
}
