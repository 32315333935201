@import "../../styles/utils";

.email-verification {
  align-items: center;
  display: flex;
  height: 80vh;
  justify-content: center;
  width: 100%;

  &__card {
    min-width: 480px;
    width: 44%;
  }

  &__header {
    font-size: $font-24;
    font-weight: $font-weight-700;
  }

  &__email-input {
    margin: 20px 0 10px;

    .rcl-popover__parent {
      bottom: 0px;
    }
  }

  &__button {
    width: 100%;
  }

  &__info-wrapper {
    align-items: start;
    column-gap: 10px;
    display: flex;
    margin-bottom: 20px;
  }

  &__info-icon {
    margin-top: 4px;
  }

  &__recaptcha {
    margin-bottom: 20px;
  }

  &__link-send {
    &-card {
      display: flex;
      flex-direction: column;
    }

    &-text {
      margin-bottom: 20px;
    }
  }
}
