@import "../../../../styles/utils";

.producer {
  &__control-body {
    min-width: 200px;
  }

  &__invoice-table {
    .table__head {
      .table__row {
        border: none;
        margin-bottom: 10px;
      }
    }

    .table__body {
      margin-top: 10px;

      .table__row {
        border: none;
        box-shadow: 0 1px 4px 0 #00393f29;
      }

      .table__field {
        border-top: none;
      }
    }
  }
}
