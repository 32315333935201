@import "../../../../styles/utils.scss";

.tvd-list {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &--emphasis-tag {
    margin: 0;
  }

  &__sidepanel {
    font-size: $font-14;

    &-label {
      font-weight: $font-weight-700;
      margin: 20px 0 5px;

      &--address {
        margin-bottom: 20px;
      }
    }

    &-label:first-child {
      margin: 0 0 5px;
    }

    &--horizontal-line {
      margin-top: 20px;
      opacity: 0.2;
    }

    &--input-field {
      margin-top: 20px;

      .organisation__form-address-city {
        flex: 30%;
      }
    }

    &--radio-button {
      margin-top: 10px;

      .radio-circle {
        border: 1.5px solid $blueish-black;
      }
    }

    &--checkbox {
      margin-top: 10px;
    }

    &--sticker-address {
      margin-bottom: 10px;
    }

    &--address-fields-wrapper {
      display: grid;
      grid-gap: 0 20px;
      grid-template-columns: repeat(2, 1fr);
    }

    &--cancel-button {
      margin-left: 10px;
    }
  }
}
