@import "../../../styles/utils";

@mixin hover-bg {
  background-color: $checkbox-hover-shadow;
  border-radius: 50%;
}

@mixin hover-bg--checked {
  background-color: $default-shadow;
}

@mixin checkbox-hover {
  content: "";
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease;
  width: 100%;
}

.rcl-checkbox {
  align-items: center;
  color: $primary-text;
  display: flex;
  font-size: 14px;
  font-weight: $bolder-font-weight;

  &--with-hint-text {
    align-items: flex-start;
  }

  &,
  * {
    box-sizing: border-box;
  }

  &__check {
    &-tooltip {
      &.rcl-tooltip.rcl-popover {
        padding: 4px 7px;
      }

      &--small {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 12px;
          }
        }
      }

      &--large {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 14px;
          }
        }
      }

      &--huge {
        &.rcl-tooltip.rcl-popover {
          .rcl-popover__content {
            font-size: 18px;
          }
        }
      }
    }
  }

  .rcl-checkbox--checked {
    background: $blueish-black;
    border: 1.5px solid $blueish-black;
    border-radius: 3px;

    &::before {
      @include checkbox-hover;
      @include hover-bg--checked;
    }

    &:hover {
      &::before {
        transform: translate(-50%, -50%) scale(2);
      }
    }

    .rcl-checkbox__check-icon {
      display: block;
    }
  }

  &__input {
    display: none;
  }

  &__check {
    align-items: center;
    border: 1px solid $leaky-rust;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: 20px;
    justify-content: center;
    position: relative;
    transition: background-color, 0.25s ease-in-out;
    width: 20px;

    &::before {
      @include checkbox-hover;
      @include hover-bg;
    }

    &:hover {
      &::before {
        transform: translate(-50%, -50%) scale(2);
      }
    }

    .rcl-checkbox__check-icon {
      display: none;
    }

    .icon {
      padding: 0 2px;
    }
  }

  &__label {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    &--header {
      flex: 1;
      font-size: $font-14;
    }

    &--hint {
      color: $disabled;
      flex: 1;
      line-height: $base-line-height;
      margin-top: 4px;
    }
  }

  &--small {
    font-size: 12px;

    .rcl-checkbox__check {
      @include size(20px, 20px);
    }

    .rcl-checkbox__label {
      color: $blueish-black;
      font-size: 14px;
      font-weight: $font-weight-400;
    }
  }

  &--tiny {
    font-size: 10px;

    .rcl-checkbox__check {
      @include size(16px, 16px);
    }

    .rcl-checkbox__label {
      font-size: 12px;
    }
  }

  &--small {
    font-size: 12px;

    .rcl-checkbox__check {
      @include size(20px, 20px);
    }

    .rcl-checkbox__label {
      font-size: 14px;
    }
  }

  &--large {
    font-size: 14px;

    .rcl-checkbox__check {
      @include size(22px, 22px);
      margin-right: 4px;
    }

    .rcl-checkbox__label {
      font-size: 16px;
    }
  }

  &--huge {
    font-size: 18px;

    .rcl-checkbox__check {
      @include size(30px, 30px);
      margin-right: 8px;
    }

    .rcl-checkbox__label {
      font-size: 20px;
    }
  }

  &--disabled {
    opacity: 0.4;
    pointer-events: none;

    &-with-hint {
      opacity: 0.4;
    }
  }
}
