.side-nav {
  height: 100%;
  position: fixed;
  top: 0;

  &__wrapper {
    .icon {
      svg,
      path {
        fill: #ffffff;
      }
    }
  }

  &__items {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    margin-left: 8px;
  }

  &__list {
    margin-top: 60px;
  }
}
